import '../styles/globals.css';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/nextjs';
import { useAuth } from '@clerk/nextjs';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from '@apollo/client/link/http';
import { AppProps } from 'next/app';

const ApolloWrapper = ({ children }: { children: JSX.Element }) => {
  const { getToken } = useAuth();

  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
    credentials: 'include',
  });
  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const App = ({ Component, pageProps }: AppProps) => (
  <ClerkProvider {...pageProps}>
    <SignedIn>
      <ApolloWrapper>
        <Component {...pageProps} />
      </ApolloWrapper>
    </SignedIn>
    <SignedOut>
      <RedirectToSignIn afterSignInUrl="/" />
    </SignedOut>
  </ClerkProvider>
);

export default App;
